<!--
#########################################################################################
######    Bottom Navigation Controller BNC10000  :  Contenido Parte Inferior       ######
#########################################################################################
-->
<template>

    <div id="igs-foot">
    </div>

</template>



<!--
##################################
###########  Código  #############
##################################
-->
<script>
import igs_mixin from '@/igs-mixins/igs_mixin'

export default {
  mixins: [ igs_mixin ],

  //------------------------ Variables Locales  ---------------------------
  data () {
    return {
    }
  },

  methods: {
  },

  mounted() {
  }
}
</script>


<!--
##################################
###########  Estilo  #############
##################################
-->
<style>
</style>