<!--
#########################################################################################
######    Vista SVC11200  :  Consulta ALTERNATIVA del Mapa del Almacén             ######
#########################################################################################
-->
<template>
  <div>
      <!-- Top Navigation (Genérico) -->
      <TNC10000></TNC10000>

      <div>{{sTitulo}}</div>

      <BNC10000></BNC10000>

  </div>
</template>

<script>
import igs_mixin from '@/igs-mixins/igs_mixin'
import TNC10000  from '@/igs-navegacion/TNC10000'
import BNC10000  from '@/igs-navegacion/BNC10000'

export default {
  mixins:       [ igs_mixin ],
  components :  { TNC10000, BNC10000 },

  //----------------------------
  //-- Datos Locales
  //----------------------------
  data() {
    return {
       //------------------------------------------------------------------------
       //-- Variables Locales
       //------------------------------------------------------------------------
        sTitulo : 'Hola SVC11200'
    }
              

  },

  //----------------------------
  //-- Métodos Locales
  //----------------------------
  methods: {

  },

  //----------------------------
  //-- Datos Calculados
  //----------------------------
  computed: {

  },

  //----------------------------
  //-- Al cargar esta página
  //----------------------------
  mounted() {
      //-- Actualizar Módulo "Vivo"
      this.$store.commit('p10StrModuleName', 'SVC11200');

      //-- Actualizar BreadCrumb 
      this.$store.commit('p10StrMenuBC', [
        {
          text: "1. MAPA",
          href: "#"
        },
        {
          text: "1.1 Diseño Mapa",
          href: "#",
          to: "/00001"
        },
        {
          text: "1.1.2 Consultar Mapa Almacén",
          active: true
        }
    ]);
  }

}
</script>

<style lang="scss">
</style>
